import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RayGrab } from "@react-three/xr";

interface ModelProps {
  path: string;
  scale: [number, number, number]; // Scale as a vector [x, y, z]
  presentationMode: boolean;
}

function Model({ path, scale, presentationMode }: Readonly<ModelProps>) {
  const modelRef = useRef();

  const { scene } = useGLTF(path);

  return presentationMode ? (
    <primitive ref={modelRef} object={scene} scale={scale} />
  ) : (
    <RayGrab>
      <primitive ref={modelRef} object={scene} scale={scale} />
    </RayGrab>
  );
}

export default Model;

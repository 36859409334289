import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { PresentationControls, useGLTF } from "@react-three/drei";

import { ARButton, XR, Controllers, Hands } from "@react-three/xr";

import Model from "./Model";
import ModelSelector from "./ModelSelector";

interface ModelItem {
  name: string;
  path: string;
}

const models: ModelItem[] = [
  { name: "EVO", path: "/EVO_new.glb" },
  { name: "LINEA", path: "/LINEA_new.glb" },
  { name: "DANCE", path: "/DANCE_new.glb" },
  { name: "AGILE", path: "/AGILE_new.glb" },
  { name: "ONDA", path: "/WAVE_new.glb" },
];

const App: React.FC = () => {
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [scale, setScale] = useState<number>(1);

  const [presentationMode, setPresentationMode] = useState<boolean>(false);

  const onSelectModel = (modelPath: string) => {
    setSelectedModel(modelPath);
  };

  const onSelectScale = (action: string) => {
    let newScale = scale;
    switch (action) {
      case "+":
        newScale = scale * 5;
        break;
      case "-":
        newScale = scale * 0.5;
        break;
      case "1":
        newScale = 1;
    }
    setScale(newScale);
  };

  const path = window.location.pathname;

  const pathSegments = path.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    if (lastSegment !== "") {
      const model = models.find((model) => model.name === lastSegment);
      if (model) {
        setSelectedModel(model.path);
        setScale(10);
        setPresentationMode(true);
      }
    }
  }, [lastSegment]);

  const isMobileiOS = iOS();

  return (
    <>
      {presentationMode && (
        <div id="view-presentation-mode">
          <div id="view-presentation-mode-content">
            <img src="https://roll-line.it/wp-content/uploads/elementor/thumbs/cropped-Roll-line-logo-pxsdya12us4pra6ykpgyng72fzp244gm8bjzz8hak6.png" />

            {isMobileiOS && (
              <a
                id={"view-ar-button"}
                rel="ar"
                href={selectedModel?.replace(".glb", ".usdz") ?? undefined}
                type="model/vnd.usdz+zip"
              >
                CLICK FOR 3D
              </a>
            )}
            {!isMobileiOS && (
              <a
                id={"view-ar-button"}
                rel="ar"
                href={getAndroidLink(selectedModel ?? "")}
              >
                CLICK FOR 3D
              </a>
            )}
          </div>
        </div>
      )}
      {presentationMode && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            justifyContent: "center",
            width: "100%",
            display: "flex",
            zIndex: 100,
          }}
        >
          <button
            id="view-presentation-mode-close"
            onClick={() => (window.location.href = "/")}
            style={{
              padding: 10,
              borderRadius: 10,
              backgroundColor: "transparent",
              color: "white",
              border: "1px solid white",
            }}
          >
            Try full VR experience
          </button>
        </div>
      )}
      {!presentationMode && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ARButton />
          </div>
        </>
      )}

      <Canvas>
        <ambientLight intensity={1} />
        {presentationMode && (
          <PresentationControls
            enabled={true} // the controls can be disabled by setting this to false
            global={false} // Spin globally or by dragging the model
            cursor={true} // Whether to toggle cursor style on drag
            snap={false} // Snap-back to center (can also be a spring config)
            speed={1} // Speed factor
            zoom={1} // Zoom factor when half the polar-max is reached
            rotation={[0, 0, 0]} // Default rotation
            polar={[0, Math.PI / 2]} // Vertical limits
            azimuth={[-Infinity, Infinity]} // Horizontal limits
            config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
          >
            <Model
              path={selectedModel ?? ""}
              presentationMode
              scale={[scale, scale, scale]}
            />
          </PresentationControls>
        )}
        {!presentationMode && (
          <XR referenceSpace="local">
            <pointLight position={[1, 1, 1]} />

            {/* Model Selector */}
            <Suspense fallback={null}>
              <ModelSelector
                models={models}
                onSelectModel={onSelectModel}
                onSelectScale={onSelectScale}
              />
            </Suspense>

            {/* Model Viewer */}
            {selectedModel && (
              <Suspense fallback={null}>
                <Model
                  path={selectedModel}
                  presentationMode={false}
                  scale={[scale, scale, scale]}
                />
              </Suspense>
            )}

            <Controllers
              rayMaterial={{ color: "blue" }}
              hideRaysOnBlur={false}
              envMapIntensity={1}
            />
            <Hands />
          </XR>
        )}
      </Canvas>
    </>
  );
};

// Preload more models as needed
models.forEach((model) => {
  useGLTF.preload(model.path);
});

export default App;

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function getAndroidLink(selectedModel: string): string {
  const host = window.location.host;
  const filePath = window.location.protocol + "//" + host + selectedModel;

  return `intent://arvr.google.com/scene-viewer/1.0?file=${filePath}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
}

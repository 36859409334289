import React, { useEffect, useRef } from "react";
import { Interactive } from "@react-three/xr";
import { Text } from "@react-three/drei";
import * as THREE from "three";
import { CompanyLogo } from "./CompanyLogo";

interface ModelItem {
  name: string;
  path: string;
}

interface ModelSelectorProps {
  models: ModelItem[];
  onSelectModel: (modelPath: string) => void;
  onSelectScale: (scale: string) => void;
}

interface SkewedBoxProps {
  text: string;
  onSelect: () => void;
  props: any;
}

function SkewedBox({ text, onSelect, props }: SkewedBoxProps): JSX.Element {
  const meshRef = React.useRef<THREE.Mesh>();

  useEffect(() => {
    if (meshRef.current) {
      const geometry = meshRef.current.geometry;
      const shearMatrix = new THREE.Matrix4();

      // Adjust these values to achieve the desired skew
      // This will skew the box along the X-axis
      const shearX = 0.5; // Adjust the shear factor

      shearMatrix.set(1, shearX, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);

      geometry.applyMatrix4(shearMatrix);
    }
  }, []);

  return (
    <Interactive onSelect={onSelect}>
      <mesh ref={meshRef} {...props}>
        <boxGeometry args={[0.4, 0.1, 0.1]} />
        <meshStandardMaterial color={"#004b93"} />
        <Text
          position={[0, 0, 0.06]}
          fontSize={0.05}
          color="white"
          anchorX="center"
          anchorY="middle"
          font="Roboto/Roboto-BlackItalic.ttf"
        >
          {text}
        </Text>
      </mesh>
    </Interactive>
  );
}

const ModelSelector: React.FC<ModelSelectorProps> = ({
  models,
  onSelectModel,
  onSelectScale,
}) => {
  return (
    <>
      <CompanyLogo position={[0, models.length * 0.2, -1]} />
      {models.map((model, index) => (
        <SkewedBox
          props={{ position: [0, index * 0.2, -1] }}
          text={model.name}
          onSelect={() => onSelectModel(model.path)}
          key={model.name}
        />
        // <group key={model.name} position={[0, index * 0.2, -1]}>
        //   <Interactive onSelect={() => onSelectModel(model.path)}>
        //     <mesh>
        //       <boxGeometry args={[0.4, 0.1, 0.1]} />
        //       <meshStandardMaterial color={"#004b93"} />
        //       <Text
        //         position={[0, 0, 0.06]}
        //         fontSize={0.05}
        //         color="white"
        //         anchorX="center"
        //         anchorY="middle"
        //       >
        //         {model.name}
        //       </Text>
        //     </mesh>
        //   </Interactive>
        // </group>
      ))}
      {/** + */}
      <group key={"+"} position={[-0.15, -0.2, -1]}>
        <Interactive onSelect={() => onSelectScale("+")}>
          <mesh>
            <sphereGeometry args={[0.04, 32, 32]} />
            <meshStandardMaterial color={"#FFE400"} />
            <Text
              position={[0, 0, 0.04]}
              fontSize={0.05}
              color="#004b93"
              anchorX="center"
              anchorY="middle"
            >
              {"+"}
            </Text>
          </mesh>
        </Interactive>
      </group>

      {/** reset */}
      <group key={"reset"} position={[0, -0.2, -1]}>
        <Interactive onSelect={() => onSelectScale("1")}>
          <mesh>
            <boxGeometry args={[0.15, 0.15, 0.05]} />
            <meshStandardMaterial color={"#004b93"} />
            <Text
              position={[0, 0, 0.03]}
              fontSize={0.05}
              color="white"
              anchorX="center"
              anchorY="middle"
            >
              {"real \nsize"}
            </Text>
          </mesh>
        </Interactive>
      </group>
      {/** - */}
      <group key={"-"} position={[+0.15, -0.2, -1]}>
        <Interactive onSelect={() => onSelectScale("-")}>
          <mesh>
            <sphereGeometry args={[0.04, 32, 32]} />
            <meshStandardMaterial color={"#FFE400"} />
            <Text
              position={[0, 0, 0.04]}
              fontSize={0.05}
              color="#004b93"
              anchorX="center"
              anchorY="middle"
            >
              {"-"}
            </Text>
          </mesh>
        </Interactive>
      </group>
    </>
  );
};

export default ModelSelector;

import { Box, useGLTF } from "@react-three/drei";
import { createPortal, useThree } from "@react-three/fiber";
import { ComponentProps } from "react";

const CompanyLogo = (props: ComponentProps<typeof Box>) => {
  const { scene } = useGLTF("/LOGO ROLL-LINE.glb");
  return <primitive {...props} object={scene} scale={[30, 30, 30]} />;
};

function FloatingCompanyLogo() {
  const camera = useThree((state) => state.camera);
  return createPortal(
    <CompanyLogo position={[0, -0.6, -1]} rotation={[-Math.PI / 5, 0, 0]} />,
    camera
  );
}

export { FloatingCompanyLogo, CompanyLogo };
